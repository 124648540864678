import React, { useEffect } from 'react';
import './App.css';
import { Crisp } from "crisp-sdk-web";

function App() {
  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search);
    const userId = params.get("vn")

    if (userId) {
      Crisp.configure("b8b95f2f-9438-470b-9909-4910cb262ca6", {
        lockMaximized: true,
        lockFullview: true
      })
      Crisp.user.setNickname(userId)
    }
  }, [])

  return (
    <div />
  );
}

export default App;
